<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import VueEasyLightbox from "vue-easy-lightbox";
import { apiRequest } from "@/helpers/api-call";

/**
 * Form validation component
 */
export default {
  page: {
    title: "Profil",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, VueEasyLightbox },
  data() {
    return {
      title: "Profil utilisateur",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Profil",
          active: true,
        },
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 5,
      pageOptions: [5, 10, 25],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      showPassword: false,
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      index: 0,
      allImage: {
        avatar: "",
        permis: "",
        vehicule: "",
        cni: "",
      },
      showretire: false,
      showSucess: false,
      showEchec: false,
      img: "",
      vue: false,
      visible: false,
      picture: ["PROFIL", "CNI", "PERMIS", "VOITURE"],
      imgs: "",

      //New variable
      designation: "",
      ifu: "",
      telephone: "",
      email: "",
      levelRate: "",
      level: "",
      rating: "",
      ville: "",
      responsable: "",
      nombreVote: 0,
      fileLogo: null,
      fileIFU: null,

      showModalChangePass: false,
      showModalEdit: false,
      showModalImage: false,
      showModalIFU: false,
      showChange: false,
      showEchecChange: false,
      newpassword: "",
      confnewpassword: "",
      idUser: JSON.parse(sessionStorage.getItem("user")).id,
      ordersData: [],
      fields: [
        { key: "nom", sortable: true, label: "Nom" },
        { key: "prenom", sortable: true, label: "Prénom" },
        { key: "type", sortable: true, label: "Type location" },
        { key: "avis", sortable: true, label: "Avis" },
        { key: "action", sortable: true, label: "Actions" },
      ],
      ifuImage:
        JSON.parse(sessionStorage.getItem("user")).imageCode != null
          ? "https://rmobility.ovh/location/image/?codeImage=" +
            JSON.parse(sessionStorage.getItem("user")).imageIFUCode
          : "",
      current_logo:
        JSON.parse(sessionStorage.getItem("user")).imageCode != null
          ? "https://rmobility.ovh/location/image/?codeImage=" +
            JSON.parse(sessionStorage.getItem("user")).imageCode
          : "",
    };
  },

  async mounted() {
    this.init();
  },
  methods: {
    //Nouvelle méthode
    async changePass() {
      console.log("idUser:", this.idUser);

      const { newpassword, confnewpassword } = this;

      if (
        newpassword == "" ||
        confnewpassword == "" ||
        newpassword != confnewpassword
      ) {
        this.showEchecChange = true;
      } else {
        const data = await apiRequest(
          "POST",
          "agence/password",
          {
            id: this.idUser,
            password: newpassword,
          },
          false
        );
        if (data && data.data) {
          this.showChange = true;
          this.showModalChangePass = false;
          this.init();
        } else {
          this.showEchecChange = true;
          this.showModalChangePass = false;
          this.init();
        }
      }
    },
    async submitModif() {
      //console.log("id:", this.idUser);

      const data = await apiRequest(
        "POST",
        "agence/update",
        {
          id: this.idUser,
          designation: this.designation,
          ifu: this.ifu,
          email: this.email,
          ville: this.ville,
          tel: this.telephone,
          responsable: this.responsable,
        },
        false
      );

      console.log("data.data:", data.data);
      if (data && data.data) {
        this.showChange = true;
        this.showModalEdit = false;
        this.init();
      } else {
        this.showEchecChange = true;
        this.showModalChangePass = false;
        this.init();
      }
    },
    async addLogo() {
      console.log("this.fileLogo:", this.fileLogo);
      if (this.fileLogo) {
        let formData = new FormData();
        //formData.append("id", this.idUser);
        formData.append("logo", this.fileLogo);
        const returnfileLogo = await apiRequest(
          "POST",
          "agence/logo",
          formData,
          true
        );

        if (returnfileLogo && returnfileLogo.data) {
          this.showModalImage = false;
          //console.log("Profil", returnfileLogo.data);
          this.init();
        }
      }
    },
    async addIFU() {
      if (this.fileIFU) {
        let formData = new FormData();
        //formData.append("id", this.idUser);
        formData.append("ifu", this.fileIFU);
        const returnfileIFU = await apiRequest(
          "POST",
          "agence/ifu",
          formData,
          true
        );
        if (returnfileIFU && returnfileIFU.data) {
          this.showModalIFU = false;
          this.init();
          //console.log("user:", this.idUser);
          //console.log("CniImage:", returnfileIFU);
        }
      }
    },
    handleFileLogo() {
      this.fileLogo = this.$refs.fileLogo.files[0];
    },
    handleFileIFU() {
      this.fileIFU = this.$refs.fileIFU.files[0];
    },

    async init() {
      console.log("id:", this.idUser);
      //Chargement des données
      const data = await apiRequest(
        "GET",
        "location/agence/?id=" + this.idUser,
        {},
        false
      );

      console.log("data.data:", data.data);
      if (data && data.data && data.data.agence) {
        let agence = data.data.agence;

        this.designation = agence.designation;
        this.ifu = agence.ifu;
        this.telephone = agence.tel;
        this.email = agence.email;
        this.levelRate = agence.levelRate;
        this.level = agence.level;
        this.rating = agence.rating;
        this.ville = agence.ville;
        this.responsable = agence.responsable;
        this.nombreVote = agence.nombreVote;
      }

      const dataChauffeur = await apiRequest(
        "GET",
        "admin/entreprise/chauffeur",
        undefined,
        false
      );
      if (dataChauffeur && dataChauffeur.data) {
        //console.log("data.data:", dataChauffeur.data);
        const formattedTableC = dataChauffeur.data.chauffeurActif.map(
          (user) => {
            return {
              id: user.id,
              nom: user.nom + " " + user.prenom,
              contact: user.contact,
            };
          }
        );
        this.chauffeurs = formattedTableC;
      }

      //Chargement des données
      const dataAvis = await apiRequest(
        "GET",
        "admin/entreprise/avis",
        undefined,
        false
      );
      if (dataAvis && dataAvis.data) {
        console.log("Avis:", dataAvis.data);
        const formattedTable = dataAvis.data.map((avis) => {
          return {
            id: avis.id,
            nom: avis.user.personne.lastname,
            prenom: avis.user.personne.firstname,
            type: avis.vehicule.types,
            avis: avis.avis,
          };
        });
        this.ordersData = formattedTable;
      }
    },

    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
    },

    showImg(index) {
      this.index = index;
      this.visible = true;
    },
    handleHide() {
      this.visible = false;
    },
    toogleShowPassword() {
      var show = document.getElementById("password");
      if (this.showPassword == false) {
        this.showPassword = true;
        show.type = "text";
      } else {
        this.showPassword = false;
        show.type = "password";
      }
    },

    tooltipForm() {
      this.submitform = true;
      this.$v.$touch();
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    showSingle() {
      this.imgs = require("@/assets/images/small/img-2.jpg");
      this.show();
    },
    showImage(img) {
      //console.log(img.srcElement.src)
      this.imgs = img.srcElement.src;
      console.log(this.imgs);
      this.show();
    },
    show() {
      this.visible = true;
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-md-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-md-3">
                <a class="image-popup-vertical-fit ml-5" @click="showImage">
                  <img
                    style="cursor: pointer"
                    class=" avatar-xl"
                    :src="`${current_logo}`"
                    data-holder-rendered="true"
                  />
                </a>
                <vue-easy-lightbox
                  :visible="vue"
                  :imgs="img"
                  @hide="handleHide"
                ></vue-easy-lightbox>
                <div class="ml-5">
                  <div class="mt-2">
                    <div>
                      <i class="text-warning mdi mdi-star"></i>{{ rating }}/5 -
                      {{ nombreVote }} avis recus
                    </div>
                  </div>
                </div>
                <a
                  class="image-popup-vertical-fit ml-5 mt-5"
                  @click="showImage"
                >
                  <img
                    style="cursor: pointer"
                    class=" avatar-xl"
                    :src="`${ifuImage}`"
                    data-holder-rendered="true"
                  />
                </a>
                <vue-easy-lightbox
                  :visible="vue"
                  :imgs="img"
                  @hide="handleHide"
                ></vue-easy-lightbox>
              </div>

              <div class="col-md-5">
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Désignation: {{ designation }}
                </div>
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  IFU: {{ ifu }}
                </div>
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Ville: {{ ville }}
                </div>

                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Contact: {{ telephone }}
                </div>
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Email: {{ email }}
                </div>
                <div class="mt-4">
                  <img
                    class="mr-2"
                    alt
                    src="@/assets/images/verification/correct.svg"
                    width="30"
                  />
                  Responsable: {{ responsable }}
                </div>
              </div>
              <div class=" col-md-3 ml-5 float-right" style="margin-top:80px;">
                <div>
                  <button
                    type="submit"
                    @click="showModalEdit = true"
                    class="btn btn-warning mb-2 mr-2"
                  >
                    <i class="mdi mdi-account-remove mr-2"></i>Modification
                  </button>
                </div>
                <div>
                  <button
                    type="submit"
                    @click="showModalImage = true"
                    class="btn btn-info mb-2 mr-2"
                  >
                    <i class="mdi mdi-account-remove mr-2"></i>Image ou logo
                  </button>
                </div>
                <div>
                  <button
                    type="submit"
                    @click="showModalIFU = true"
                    class="btn btn-info mb-2 mr-2"
                  >
                    <i class="mdi mdi-account-remove mr-2"></i>Image IFU
                  </button>
                </div>
                <div>
                  <button
                    type="submit"
                    @click="showModalChangePass = true"
                    title="Modification mot de passe"
                    class="btn btn-danger mb-2 mr-2"
                  >
                    <i class="fas fa-lock align-middle mr-2"></i>Modification du
                    mot de passe
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body pt-0">
            <b-tabs nav-class="nav-tabs-custom">
              <b-tab title-link-class="p-3">
                <template v-slot:title>
                  <a class="font-weight-bold active">Avis</a>
                </template>
                <div class="row mt-4">
                  <div class="col-sm-12 col-md-3">
                    <div id="tickets-table_length" class="dataTables_length">
                      <label class="d-inline-flex align-items-center">
                        Afficher&nbsp;
                        <b-form-select
                          v-model="perPage"
                          size="sm"
                          :options="pageOptions"
                        ></b-form-select
                        >&nbsp;entrées
                      </label>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <!-- <label class="d-inline-flex align-items-center">
                        Choisir
                    <multiselect
                    v-model="value1"
                    :options="options"
                    :multiple="true"
                    :searchable="false" class="col-md-12"
                    ></multiselect>
                    </label> -->
                  </div>
                  <!-- Search -->
                  <div class="col-sm-12 col-md-3">
                    <div
                      id="tickets-table_filter"
                      class="dataTables_filter text-md-right"
                    >
                      <label class="d-inline-flex align-items-center">
                        Rechercher:
                        <b-form-input
                          v-model="filter"
                          type="search"
                          class="form-control form-control-sm ml-2"
                        ></b-form-input>
                      </label>
                    </div>
                  </div>
                  <!-- End search -->
                </div>
                <div class="table-responsive">
                  <b-table
                    style="cursor: pointer"
                    class="table-centered"
                    :items="ordersData"
                    :fields="fields"
                    responsive="sm"
                    :per-page="perPage"
                    :current-page="currentPage"
                    :sort-by.sync="sortBy"
                    :sort-desc.sync="sortDesc"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                    @filtered="onFiltered"
                    @row-clicked="goToDetail"
                  >
                    <template v-slot:cell(badge)="row">
                      <div
                        class="py-2 d-flex justify-content-center"
                        :class="{
                          'fa fa-star': `${row.value}` === 'false',
                          'fa fa-star badge-soft-warning':
                            `${row.value}` === 'true',
                        }"
                      ></div>
                    </template>
                    <template v-slot:cell(statut)="row">
                      <div
                        class="py-2 d-flex justify-content-center badge font-size-12"
                        :class="{
                          'badge-soft-danger': `${row.value}` === 'Suspendu',
                          'badge-soft-success': `${row.value}` === 'Actif',
                        }"
                      >
                        {{ row.value }}
                      </div>
                    </template>
                    <template v-slot:cell(action)="row">
                      <!-- <a
                        href="javascript:void(0);"
                        class="text-primary"
                        v-b-tooltip.hover
                        @click="editClick(row.item)"
                        title="Editer"
                      >
                        <i class="mdi mdi-pencil font-size-18"></i>
                      </a> -->
                      <a
                        href="javascript:void(0);"
                        class="text-danger"
                        v-b-tooltip.hover
                        @click="deleteUser(row.item)"
                        title="Supprimer"
                      >
                        <i class="mdi mdi-trash-can font-size-18"></i>
                      </a>
                      <!-- <a
                        href="javascript:void(0);"
                        class="text-success"
                        v-b-tooltip.hover
                        @click="desactiverUser(row.item)"
                        title="désactiver"
                      >
                        <i class="mdi mdi-close font-size-18"></i>
                      </a>
                       <a
                        href="javascript:void(0);"
                        class="text-success"
                        v-b-tooltip.hover
                        @click="messageShow(row.item)"
                        title="Mail"
                      >
                        <i class="fas fa-envelope font-size-16"></i>
                      </a> -->
                    </template>
                  </b-table>
                </div>
                <div class="row">
                  <div class="col">
                    <div
                      class="dataTables_paginate paging_simple_numbers float-right"
                    >
                      <ul class="pagination pagination-rounded mb-0">
                        <!-- pagination -->
                        <b-pagination
                          v-model="currentPage"
                          :total-rows="rows"
                          :per-page="perPage"
                        ></b-pagination>
                      </ul>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <!-- Nouveau -->

    <!-- CHANGER LE MOT DE PASSE -->
    <b-modal
      v-model="showModalChangePass"
      title="Changer le mot de passe"
      centered
    >
      <form>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            placeholder="Mot de passe"
            v-model="newpassword"
            required
          />
        </div>
        <div class="form-group">
          <input
            type="password"
            class="form-control"
            placeholder="Confirmation Mot de passe"
            v-model="confnewpassword"
            required
          />
        </div>
      </form>
      <template v-slot:modal-footer>
        <b-button variant="secondary" @click="showModalChangePass = false"
          >Fermer</b-button
        >
        <b-button variant="primary" @click="changePass">
          Appliquer
          <i class="fas fa-key ml-1"></i>
        </b-button>
      </template>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showChange"
      title="Modification effectué avec succès !!!"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showChange = false">OK</b-button>
      </div>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showEchecChange"
      title="Erreur Reéssayez"
      title-class="font-18"
      hide-footer
    >
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showEchecChange = false"
          >Reéssayez</b-button
        >
      </div>
    </b-modal>

    <!-- MODIFIER LES INFOS -->
    <b-modal
      id="modal-center"
      centered
      v-model="showModalEdit"
      title="Modifier"
      title-class="font-18"
      hide-footer
    >
      <form
        class="form-horizontal"
        role="form"
        method="post"
        @submit.prevent="submitModif"
      >
        <div class="row">
          <div class="col-md-4 mb-3">
            <label for="designation">Désignation</label>
            <input
              type="text"
              class="form-control py-4"
              id="designation"
              v-model="designation"
            />
          </div>
          <div class="col-md-4 mb-3">
            <label for="ifu">IFU</label>
            <input
              type="text"
              class="form-control py-4"
              id="ifu"
              v-model="ifu"
            />
          </div>
          <div class="col-md-4 mb-3">
            <label for="responsable">Responsable</label>
            <input
              type="text"
              class="form-control "
              id="responsable"
              v-model="responsable"
            />
          </div>
          <div class="col-md-4 mb-3">
            <label for="">Ville</label>
            <input type="text" class="form-control py-4" v-model="ville" />
          </div>

          <div class="col-md-4 mb-3">
            <label for="telephone">Téléphone</label>
            <input
              type="text"
              class="form-control py-4"
              id="telephone"
              v-model="telephone"
            />
          </div>
          <div class="col-md-4 mb-3">
            <label for="firstname">Email</label>
            <input
              type="text"
              class="form-control py-4"
              id="firstname7"
              v-model="email"
            />
          </div>
        </div>
        <button class="btn btn-success float-right" type="submit">
          Modifier
        </button>
      </form>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showModalImage"
      title="Téléverser ce logo ?"
      title-class="font-18"
      hide-footer
    >
      <form action="">
        <input
          type="file"
          id="fileLogo"
          ref="fileLogo"
          v-on:change="handleFileLogo"
          class="form-control"
          placeholder="Logo"
        />
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="showModalImage = false"
            >Non</b-button
          >
          <b-button variant="success" class="ml-3 mr-4" @click="addLogo"
            >Oui</b-button
          >
        </div>
      </form>
    </b-modal>

    <b-modal
      id="modal-backdrop"
      centered
      v-model="showModalIFU"
      title="Téléverser ce Fichier ?"
      title-class="font-18"
      hide-footer
    >
      <form action="">
        <input
          type="file"
          id="fileIFU"
          ref="fileIFU"
          v-on:change="handleFileIFU"
          class="form-control"
          placeholder="Photo de l'IFU"
        />
        <div class="row float-right mt-3">
          <b-button variant="danger" @click="showModalIFU = false"
            >Non</b-button
          >
          <b-button variant="success" class="ml-3 mr-4" @click="addIFU"
            >Oui</b-button
          >
        </div>
      </form>
    </b-modal>
    <!-- -->
  </Layout>
</template>

<style>
label {
  font-size: 20px;
}
.divider {
  width: 100%;
  border: solid 2px blue;
}
.blueG {
  border: solid 1px #8608b8ad;
}
.YelG {
  border: solid 1px #ff6545;
}
.text-blueG {
  color: #8608b8ad;
}
</style>
